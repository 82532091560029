/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.slick-slider').slick({
          autoplay: true,
          arrows: false,
          autoplaySpeed: 6000,
          lazyLoad: 'ondemand',
          fade: true,
          speed: 500
        });
        if($('body').hasClass('single-sec_filing') && !$('.filelist').length){
          window.location.replace("//"+window.location.hostname+"/sec-filings");
        }
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(window).resize(function(){
          if(window.innerWidth < 768){
            var callouts = [];
            $(".carbon_fields_theme_widget").each(function(){
              if($(this).is(':visible')){
                callouts.push($(this));
              }
            });
            if(callouts.length > 0){
              calloutsSize = (1/callouts.length)*100;
              $.each(callouts,function(){
                $(this).css('width',calloutsSize+"%");
              });
            }
          }else{
            $(".carbon_fields_theme_widget").each(function(){
              if($(this).is(':visible')){
                $(this).css('width','100%');
              }
            });
          }
        }).resize();
        $('.sec_delete_link').on('click', function () {
          return confirm('Are you sure?');
        });
        $('.compliance_delete_link').on('click', function () {
          return confirm('Are you sure?');
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
